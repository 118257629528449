/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Vimeo from "@u-wave/react-vimeo";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A modern take on a classic by Chopin, with a ", React.createElement(_components.em, null, "modern commentary"), " at the bottom of the video."), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "I enjoyed listening while reading the comments, and reflecting a little on how things\nchange in time! (Better when watched in full-screen mode)"), "\n", React.createElement("br"), "\n", React.createElement("br"), "\n", "\n", React.createElement("div", {
    style: {
      padding: "56.25% 0 0 0",
      position: "relative"
    }
  }, React.createElement("iframe", {
    src: "https://player.vimeo.com/video/785437528?h=dde44f1312",
    style: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    },
    frameborder: "0",
    allow: "autoplay; fullscreen; picture-in-picture",
    allowfullscreen: true
  })), "\n", React.createElement("script", {
    src: "https://player.vimeo.com/api/player.js"
  }), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://soundcloud.com/chopin-frederic/nocturne-op9-no2"
  }, "Original")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
